import React, { ReactNode } from "react";

export const SkeletonRect: React.FC<{
  className?: string;
}> = ({ className = "" }) => {
  return <div className={`bg-neutral-20 rounded-2 ${className}`} />;
};

export const SkeletonCircle: React.FC<{
  className?: string;
  size?: number;
}> = ({ className = "", size = 32 }) => {
  return (
    <div
      className={`bg-neutral-20 rounded-full ${className}`}
      style={{
        width: size + "px",
        height: size + "px",
      }}
    />
  );
};

export const SkeletonWrapper: React.FC<{
  className?: string;
  children: ReactNode;
}> = ({ className = "", children }) => {
  return <div className={`animate-pulse ${className}`}>{children}</div>;
};
