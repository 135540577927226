import { useSystemStore } from "components/PageHeader/systemStore";

type Props = {
  size?: number;
  color?: string;
  classes?: string;
};

export const IconDashboard: React.FC<Props> = ({
  size = 24,
  color = "#3E3851",
  classes = "",
}) => {
  const theme = useSystemStore((state) => state.theme);

  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
    >
      <path
        d="M12.5 6C12.2348 6 11.9804 6.10536 11.7929 6.29289C11.6054 6.48043 11.5 6.73478 11.5 7V17C11.5 17.2652 11.6054 17.5196 11.7929 17.7071C11.9804 17.8946 12.2348 18 12.5 18C12.7652 18 13.0196 17.8946 13.2071 17.7071C13.3946 17.5196 13.5 17.2652 13.5 17V7C13.5 6.73478 13.3946 6.48043 13.2071 6.29289C13.0196 6.10536 12.7652 6 12.5 6ZM7.5 12C7.23478 12 6.98043 12.1054 6.79289 12.2929C6.60536 12.4804 6.5 12.7348 6.5 13V17C6.5 17.2652 6.60536 17.5196 6.79289 17.7071C6.98043 17.8946 7.23478 18 7.5 18C7.76522 18 8.01957 17.8946 8.20711 17.7071C8.39464 17.5196 8.5 17.2652 8.5 17V13C8.5 12.7348 8.39464 12.4804 8.20711 12.2929C8.01957 12.1054 7.76522 12 7.5 12ZM17.5 10C17.2348 10 16.9804 10.1054 16.7929 10.2929C16.6054 10.4804 16.5 10.7348 16.5 11V17C16.5 17.2652 16.6054 17.5196 16.7929 17.7071C16.9804 17.8946 17.2348 18 17.5 18C17.7652 18 18.0196 17.8946 18.2071 17.7071C18.3946 17.5196 18.5 17.2652 18.5 17V11C18.5 10.7348 18.3946 10.4804 18.2071 10.2929C18.0196 10.1054 17.7652 10 17.5 10ZM19.5 2H5.5C4.70435 2 3.94129 2.31607 3.37868 2.87868C2.81607 3.44129 2.5 4.20435 2.5 5V19C2.5 19.7956 2.81607 20.5587 3.37868 21.1213C3.94129 21.6839 4.70435 22 5.5 22H19.5C20.2956 22 21.0587 21.6839 21.6213 21.1213C22.1839 20.5587 22.5 19.7956 22.5 19V5C22.5 4.20435 22.1839 3.44129 21.6213 2.87868C21.0587 2.31607 20.2956 2 19.5 2ZM20.5 19C20.5 19.2652 20.3946 19.5196 20.2071 19.7071C20.0196 19.8946 19.7652 20 19.5 20H5.5C5.23478 20 4.98043 19.8946 4.79289 19.7071C4.60536 19.5196 4.5 19.2652 4.5 19V5C4.5 4.73478 4.60536 4.48043 4.79289 4.29289C4.98043 4.10536 5.23478 4 5.5 4H19.5C19.7652 4 20.0196 4.10536 20.2071 4.29289C20.3946 4.48043 20.5 4.73478 20.5 5V19Z"
        fill={theme == "dark" ? "#FFFFFF" : color}
      />
    </svg>
  );
};
