import { DownloadAppBanner } from "components/Banner/DownloadAppBanner";
import { IconDashboard } from "components/Icon/IconDashboard";
import { IconHome } from "components/Icon/IconHome";
import { IconPlayCircle } from "components/Icon/IconPlayCircle";
import { IconSearch } from "components/Icon/IconSearch";
import { Logo } from "components/Logo";
import { useSystemStore } from "components/PageHeader/systemStore";
import { SkeletonRect, SkeletonWrapper } from "components/Skeleton/Skeleton";
import { trackEvent } from "lib/amplitude";
import { isBlueBirdURL, useIsSubdomain, useIsTenant } from "lib/host";
import { ReduceArrayFromDuplicate } from "lib/object";
import { useAuthStore } from "modules/Auth/authStore";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import React from "react";
import { useEffect, useState } from "react";

export const iconColor = {
  default: "#817E8C",
  selected: "#19093A",
};

const labelColor = {
  default: "text-neutral-50 text-c1 font-light text-center",
  selected: "text-purple-80 text-c1 font-bold text-center",
};

interface MenuProps {
  id?: string;
  href?: string;
  label?: string;
}

export const BottomNav: React.FC<{
  isDesktop?: boolean;
  className?: string;
}> = ({ isDesktop, className }) => {
  const loginState = useAuthStore((state) => state.loginState);
  const router = useRouter();
  const isTenant = useIsTenant();
  const isSubdomainTenant = useIsSubdomain();
  const currentUser = useAuthStore((state) => state.currentUser);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useSystemStore((state) => state.theme);
  const isBlueBirdTenant =
    typeof window !== "undefined" && isBlueBirdURL(window.location.hostname);

  const [menus, setMenus] = useState([
    {
      id: "my-home",
      href: "/",
      label: "Home",
    },
    {
      id: "my-class",
      href: loginState == "LOGIN" ? "/my-class" : "/login?url=my-class",
      label: "Kelas Saya",
    },
  ]);

  const trackNavbar = (type: string) => {
    trackEvent({
      event: "nav_a",
      property: {
        button_type: type,
        page: router.pathname,
      },
    });
  };

  const { t } = useTranslation("common");

  useEffect(() => {
    // Add dashboard menu
    if (
      currentUser &&
      currentUser?.dashboard_url &&
      menus.length &&
      !menus.some((a) => a.id == "dashboard")
    ) {
      setTimeout(() => {
        // menus.push({
        //   id: "dashboard",
        //   href: "/dashboard",
        //   label: "Dashboard",
        // });
        setMenus((prevMenus) => [
          ...prevMenus,
          {
            id: "dashboard",
            href: "/dashboard",
            label: "Dashboard",
          },
        ]);
        setIsLoading(false);
      }, 1000);
    }
    // Add my account menu
    if (currentUser && menus.length && !isTenant) {
      setTimeout(() => {
        // menus.push({
        //   id: "my-account",
        //   href: "/account",
        //   label: "Akun Saya",
        // });
        setMenus((prevMenus) => [
          ...prevMenus,
          {
            id: "my-account",
            href: "/account",
            label: "Akun Saya",
          },
        ]);
        setIsLoading(false);
      }, 1000);
    }
    // Show bottom nav for tenant & b2c
    else {
      setIsLoading(false);
    }
  }, [currentUser, isSubdomainTenant, isTenant]);

  const renderUser = () => {
    const initialName = currentUser?.name
      ? currentUser?.name[0].toUpperCase() ?? "U"
      : currentUser?.email
      ? currentUser?.email[0].toUpperCase() ?? "U"
      : "U";

    return (
      <div className="cursor-pointer w-24 h-24 rounded-full flex items-center justify-center text-b1 text-purple60_to_white bg-purple-10">
        {initialName}
      </div>
    );
  };

  return (
    <div className="sticky z-10 bottom-0 left-0 right-0">
      {/* {!isDesktop && <DownloadAppBanner />} */}
      <nav className="flex justify-center z-10 bg-neutral-10 md:hidden">
        {/* hide max-w-[720px] to make sure the background is full  */}
        <div className="relative w-full h-72 bg-white flex items-center justify-evenly px-8 gap-12 border-t border-neutral20_to_dark30">
          {menus && menus.length && !isLoading ? (
            ReduceArrayFromDuplicate(menus).map((menu: MenuProps) => {
              let colors =
                router.pathname == menu.href
                  ? iconColor.selected
                  : iconColor.default;

              let labels =
                router.pathname == menu.href
                  ? labelColor.selected
                  : labelColor.default;
              return (
                <div
                  // href={menu.href as any}
                  key={"Nav-" + menu.id}
                  onClick={() => {
                    //@ts-ignore
                    router.push(menu.href);
                    trackNavbar(menu.label);
                  }}
                >
                  <div className="p-5 w-fit">
                    {
                      {
                        "my-home": (
                          <div className="flex flex-col gap-2 items-center">
                            <IconHome color={colors} classes="m-auto" />
                            <div className={labels}>{t(menu.label)}</div>
                          </div>
                        ),
                        "my-search": (
                          <div className="flex flex-col gap-2 items-center">
                            <IconSearch color={colors} classes="m-auto" />
                            <div className={labels}>{t(menu.label)}</div>
                          </div>
                        ),
                        "my-class": (
                          <div className="flex flex-col gap-2 items-center">
                            <IconPlayCircle color={colors} classes="m-auto" />
                            <div className={labels}>{t(menu.label)}</div>
                          </div>
                        ),
                        dashboard: (
                          <div className="flex flex-col gap-2 items-center">
                            <IconDashboard color={colors} classes="m-auto" />
                            <div className={labels}>{menu.label}</div>
                          </div>
                        ),
                        "my-account": (
                          <div className="flex flex-col gap-2 place-items-center">
                            {renderUser()}
                            <div className={labels}>{t(menu.label)}</div>
                          </div>
                        ),
                      }[menu.id]
                    }
                  </div>
                </div>
              );
            })
          ) : isLoading ? (
            <SkeletonWrapper className="grid grid-cols-4 p-5 gap-8">
              {[1, 2, 3, 4].map((el) => (
                <SkeletonRect key={el} className="rounded-4 h-48 w-80" />
              ))}
            </SkeletonWrapper>
          ) : null}
        </div>
      </nav>
      {isBlueBirdTenant && !isDesktop && (
        <>
          <div className="border-b border-b-neutral20_to_dark30"></div>
          <div className="bg-white flex justify-center items-center gap-8 py-4">
            <p className="text-b2 font-light text-neutral50_to_dark20">
              Powered by
            </p>
            <Logo scale={1.6} useWhite={theme == "dark"} />
          </div>
        </>
      )}
    </div>
  );
};
