import { useSystemStore } from "components/PageHeader/systemStore";
import React from "react";

type Props = {
  size?: number;
  color?: string;
  classes?: string;
  isBgCustomized?: boolean;
  className?: string;
};

export const IconPlayCircle: React.FC<Props> = ({
  size = 24,
  color = "#817E8C",
  classes = "",
  isBgCustomized = false,
  className = "fill-neutral-80",
}) => {
  const theme = useSystemStore((state) => state.theme);

  if (isBgCustomized) {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-neutral100_to_purple50"
      >
        <rect
          x="0.667969"
          y="0.666016"
          width="26.6667"
          height="26.6667"
          rx="13.3333"
          // fill={color}
          fillOpacity="1"
        />
        <path
          d="M18.5425 11.917L11.8341 8.06974C11.4711 7.86013 11.0591 7.75032 10.64 7.75147C10.2208 7.75263 9.8094 7.86469 9.44756 8.0763C9.08572 8.2879 8.78632 8.5915 8.57979 8.95625C8.37326 9.32101 8.26694 9.73393 8.27163 10.1531V17.8753C8.27163 18.5052 8.52186 19.1093 8.96725 19.5547C9.41265 20.0001 10.0167 20.2503 10.6466 20.2503C11.0636 20.2496 11.4731 20.1394 11.8341 19.9308L18.5425 16.0836C18.9029 15.875 19.2022 15.5753 19.4102 15.2145C19.6183 14.8537 19.7278 14.4445 19.7278 14.0281C19.7278 13.6116 19.6183 13.2024 19.4102 12.8417C19.2022 12.4809 18.9029 12.1811 18.5425 11.9725V11.917Z"
          fill="white"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
          fill={theme == "dark" ? "#FFFFFF" : color}
        />
        <path
          d="M10.4903 15.4533L15.4505 12.6632C16.1302 12.2809 16.1302 11.3023 15.4505 10.92L10.4903 8.12988C9.82366 7.75492 9 8.23663 9 9.00146V14.5818C9 15.3466 9.82366 15.8283 10.4903 15.4533Z"
          fill={theme == "dark" ? "#FFFFFF" : color}
        />
      </svg>
    );
  }
};
