import React, { ReactNode, use } from "react";
import { BottomNav } from "modules/Nav/BottomNav";
import { BarLoader, ClipLoader, MoonLoader } from "react-spinners";
import { useIsDesktop } from "lib/device";
import { useRouter } from "next/router";

export type Props = {
  children?: ReactNode;
  className?: string;
  wrapperClassName?: string;
  isFullHeight?: boolean;
  useNavbar?: boolean;
  isLoader?: boolean;
  useLoader?: boolean;
};

const Container: React.FC<Props> = ({
  children,
  className = "",
  wrapperClassName = "",
  useNavbar = false,
  isLoader = false,
  useLoader = false,
}) => {
  const isDesktop = useIsDesktop();
  const router = useRouter();

  return (
    <div className={["bg-neutral-10", wrapperClassName].join(" ")}>
      <div
        className={[
          "relative max-w-container mx-auto bg-white ",
          className,
        ].join(" ")}
      >
        {children}

        {useLoader && isLoader && (
          <div className="flex w-full items-center justify-center h-[80vh]">
            <MoonLoader loading={isLoader} />
          </div>
        )}
      </div>

      {useNavbar && <BottomNav />}
    </div>
  );
};

export default Container;

export const ContainerDesktop = ({
  children,
  className = "",
  classBackgroundFull = "",
  useMinHeight = false,
  useAuto = false,
  customStyle = null,
}) => {
  return (
    <div
      className={
        `w-full ${useMinHeight ? "min-h-content" : "h-full"} ` +
        (classBackgroundFull ? classBackgroundFull : " bg-transparent")
      }
      style={customStyle != null ? customStyle : null}
    >
      <div
        // className={`mx-auto !max-w-[1366px] w-[95%] ${
        className={`${
          useAuto ? "mx-auto !max-w-[1366px] w-[95%] " : "px-16 md:px-20"
        } ${classBackgroundFull ? "relative h-full" : ""} ${className}`}
      >
        {children ?? null}
      </div>
    </div>
  );
};
